<template>
  <div class="footer">
    <div class="footer_wrapper">
      <div class="wrapper_header">
        <div class="list">
          <div>
            <b style="margin-top: 20px">基金资讯</b><br /><a>新闻资讯</a
            ><br /><a>党建资讯</a>
          </div>
          <div>
            <b>基金项目</b>
          </div>
          <div>
            <b>信息公开</b>
          </div>
          <div>
            <b>基金党建</b><br /><a>审计报告</a><br/><a>年检报告</a>
          </div>
          <div>
            <b>章程</b><br /><a>管理制度</a><br /><a>理事会名单</a
            ><br /><a>组织结构</a><br /><a>登记证书</a> <br />
            <a>联系我们</a>
          </div>
        </div>
        <div class="ewm">
          <!-- <img
            style="width: 100px; height: 100px; padding: 0px 100px 0px 0px"
            src="../../../public/image/ewm.png"
            alt=""
          /> -->
        </div>
      </div>
      <!-- <div class="wrapper_bottom">
        <p style="margin: 0 auto; width: 100%; max-width: 1200px">
          Copyright © 2019 浙江大学基础医学院系统医学研究中心 技术支持 :
          创高软件 后台管理 您是本站第 22615 位访客
        </p>
      </div> -->
    </div>
    <div class="footer_min">
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item title="基金咨询" name="1" >
            <div> 新闻资讯 </div>
            <div> 党建资讯 </div>
        </el-collapse-item> 
         <el-collapse-item title="基金项目" name="2">
           
        </el-collapse-item> 
         <el-collapse-item title="信息公开" name="3">
     
        </el-collapse-item> 
         <el-collapse-item title="基金党建" name="4">
            <div> 审计报告 </div>
            <div> 年检报告 </div>
        </el-collapse-item> 
         <el-collapse-item title="章程" name="5">
            <div> 章程 </div>
            <div> 管理制度 </div>
            <div> 理事会名单 </div>
            <div> 组织结构 </div>
            <div> 登记证书 </div> 
            <div> 联系我们 </div> 
        </el-collapse-item> 
      </el-collapse>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "0",
    };
  },
};
</script>
<style scoped>
@media screen and (min-width: 1200px) {
  .footer {
    width: 100%;
    background-color: #003f88;
  }
  .footer_wrapper {
    width: 100%;
    margin: 0 auto;
    background-color: #f2f3f3;
  }
  .wrapper_header {
    max-width: 1200px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .list {
    width: 800px;
    margin-top: 20px;
  }
  .list > div {
    float: left;
    margin: 0px 30px;
  }
  .list > div b {
    padding: 10px 0px;
    height: 50px;
    line-height: 50px;
  }
  .list > div a {
    margin: 20px 0px 0px 0px;
    height: 30px;
    line-height: 30px;
  }
  .wrapper_header a {
    font-size: 14px;
    color: #666666;
  }
  .center_detail {
    width: 340px;
    margin-right: 100px;
    /* color: #fff; */
  }
  .otherTwo {
    /* width: 1200px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 20px 0px 0px 50px;
  }
  .friend_href {
  }
  .wrapper_bottom {
    height: 58px;
    width: 100%;
    line-height: 58px;
    background-color: #eaebeb;
    font-size: 14px;
    color: #666666;
    text-align: center;
  }
  .footer_min {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .footer_wrapper {
    display: none;
  }
  .footer_min {
    display: block;
  }
  .infolist {
    width: 100%;
    height: 54px;
    background-color: #eaebeb;
  }
  .wrapper_header {
    max-width: 1200px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: #f2f3f3;
  }
  .left_logo {
    width: 520px;
    height: 120px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px 0px 0px;
  }
  .otherTwo {
    /* width: 1200px; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 0px 0px 50px;
  }
  /* .left_logo img {
        margin: 0 auto;
    } */
  .left_logo img {
    width: 461px;
    height: 100%;
  }
  .center_detail {
    width: 340px;
    color: #fff;
  }
  .friend_href {
  }
  .wrapper_bottom {
    height: 58px;
    width: 100%;
    line-height: 58px;
    /* border-top: 1px solid #295E9B; */
    text-align: center;
    background-color: #eaebeb;
  }
}
.el-collapse-item__header:hover {
    cursor: pointer;
}
.el-collapse-item__content:hover {
    cursor: pointer;
}
</style>