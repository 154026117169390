<template>
  <div class="header">
    <!-- <div :class="a == 1 ? 'banner' : 'banner2'"   ref="addCla" @click="bgqh3($event)"> -->
    <div class="header_wrap">
      <div style="poition:relative;display:flex;">
        <!-- <img src="../../../public/image/flower.jpg" alt="" /> -->
        <a style="font-size:32px;padding-right:20px">北京黉塾公益基金会</a>
      </div>
      <div class="headr_right">
        <img class="maximg"
          src="../../../public/image/yh.png"
          alt=""
        />
        <a class="maxshow"
          style="margin-left: 100rpx;
          "
          >办公室电话：010-82616015</a
        >
        <a class="maxshow" 
            style="padding:0px 0px 0px 20px"
          >中文</a>
        <span class="minlieb"><img @click="drawer = true" style="width:50px;height:50px" src="../../../public/image/lieB.png" alt=""></span>
      </div>
    </div>

    <div class="tabber">
      <div
        style="
          max-width: 1200px;
          margin: 0 auto;
          display: flex;
          justify-content: space-around;
          align-items: center;
        "
      >
        <div
          v-for="(item, index) in tabberList"
          :key="index"
          class="tabberList"
        >
          <div
            @mouseenter="shouwmore(index)"
            @mouseleave="hiddenMoreFromUp(index)"
            class="titlename"
            @click="toNext(item)"
          >
            {{ item.name }}
          </div>
          <div @mouseleave="hiddenMore()" @mouseenter="intoChildIndex()">
            <div
              class="moreList"
              v-show="hoverItem == index"
              v-for="(items, indexs) in item.childItem"
              :key="indexs"
              @click="toChildNext(item, indexs)"
            >
              {{ items.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      :with-header="false"
      direction="ltr"
      size="100%"
      :show-close="true"
    >
      <el-row class="tac">
        <el-col :span="12" style="width: 100%; color: #1b5597">
          <el-menu
            default-active="2"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            text-color="#1B5597"
            :router="false"
          >
            <el-menu-item index="1">
              <!-- <i class="el-icon-menu"></i> -->
              <div
                slot="title"
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <a>首页</a> <a style="font-size: 20px" @click="closecT">×</a>
              </div>
            </el-menu-item>
            <el-submenu index="2">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>基金资讯</span>
              </template>
              <el-submenu index="2-1">
                <template slot="title">新闻资讯</template>
                <!-- <el-menu-item index="1-1-1">选项1</el-menu-item> -->
              </el-submenu>
              <el-submenu index="2-2">
                <template slot="title">党建资讯</template>
                <!-- <el-menu-item index="1-2-1">选项1</el-menu-item> -->
              </el-submenu>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>基金项目</span>
              </template>
              <el-submenu index="3-1">
                <template slot="title">基金项目</template>
                <!-- <el-menu-item index="1-1-1">选项1</el-menu-item> -->
              </el-submenu>
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>信息公开</span>
              </template>
              <el-submenu index="4-1">
                <template slot="title">信息公开</template>
                <!-- <el-menu-item index="1-1-1">选项1</el-menu-item> -->
              </el-submenu>
            </el-submenu>
            <el-submenu index="5">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>基金党建</span>
              </template>
              <el-submenu index="5-1">
                <template slot="title">审计报告</template>
                <!-- <el-menu-item index="1-1-1">选项1</el-menu-item> -->
              </el-submenu>
              <el-submenu index="5-2">
                <template slot="title">年检报告</template>
                <!-- <el-menu-item index="1-1-1">选项1</el-menu-item> -->
              </el-submenu>
            </el-submenu>
            <el-submenu index="6">
              <template slot="title">
                <!-- <i class="el-icon-location"></i> -->
                <span>关于我们</span>
              </template>
              <el-submenu index="6-1">
                <template slot="title">章程</template>
                <!-- <el-menu-item index="1-1-1">选项1</el-menu-item> -->
              </el-submenu>
              <el-submenu index="6-2">
                <template slot="title">管理制度</template>
                <!-- <el-menu-item index="1-1-1">选项1</el-menu-item> -->
              </el-submenu>
              <el-submenu index="6-3">
                <template slot="title">理事会名单</template>
                <!-- <el-menu-item index="1-1-1">选项1</el-menu-item> -->
              </el-submenu>
              <el-submenu index="6-4">
                <template slot="title">组织结构</template>
                <!-- <el-menu-item index="1-1-1">选项1</el-menu-item> -->
              </el-submenu>
              <el-submenu index="6-5">
                <template slot="title">登记证书</template>
                <!-- <el-menu-item index="1-1-1">选项1</el-menu-item> -->
              </el-submenu>
              <el-submenu index="6-6">
                <template slot="title">联系我们</template>
                <!-- <el-menu-item index="1-1-1">选项1</el-menu-item> -->
              </el-submenu>
            </el-submenu>
          </el-menu>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>
<script>
// function a(fn,delay){
//   var t=null;
//        if(t){
//            clearTimeout(t)
//        }else {
//          t=setTimeout(fn,delay)
//        }
// }
// import Swiper from "swiper"
import eventbus from "@/api/eventbus";
export default {
  data() {
    return {
      tabberList: [
        {
          name: "首页",
          path: "/",
        },
        {
          name: "基金资讯",
          path: "/zxun",
          childItem: [
            {
              name: "新闻资讯",
            },
            {
              name: "党建资讯",
            },
          ],
        },
        {
          name: "基金项目",
          path: "/xmu",
          childItem: [
               {
              name: "基金项目",
            },
          ],
        },
        {
          name: "信息公开",
          path: "/info",
          childItem: [
              {
              name: "信息公开",
            },
          ],
        },
        {
          name: "基金党建",
          path: "/djian",
          childItem: [
            {
              name: "审计报告",
            },
            {
              name: "年检报告",
            },
          ],
        },
        {
          name: "关于我们",
          path: "/aours",
          childItem: [
           {
              name: "章程",
            },{
              name: "管理制度",
            },{
              name: "理事会名单",
            },{
              name: "组织结构",
            },{
              name: "登记证书",
            },{
              name: "联系我们",
            },
          ],
        },
    
      ],
      hoverItem: 0,
      toLong: false,
      drawer: false,
      screenWidth: "",
      timer: false,
      screenHeight: "",
      intoChild: false,
      a: 1,
      timers:null,
    };
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    // this.screenHeight = document.body.clientHeight;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        // this.screenHeight = document.body.clientHeight;
      })();
    };
  },
  methods: {
      showList(){

      },
    bgClick() {
      this.a = 2;
    },
    bgClick1() {
      this.a = 1;
    },
    bgClick2(){
      this.a = 3;
    },
    bgqh3(){
    },
    toNext(item) {
      // location.reload();
      // eventbus.$emit('headerTitle',item)
      window.sessionStorage.setItem("titleMessage", JSON.stringify(item));
      this.$router.push({
        path: item.path,
        query: {
          index: 0,
        },
      });
    },
    toChildNext(item, index) {
      // console.log(item)  
      eventbus.$emit("childindex", index);
      window.sessionStorage.setItem("titleMessage", JSON.stringify(item));
      this.hoverItem = -1;
      this.$router.push({
        path: item.path,
        query: {
          index: index,
        },
      });
    },
    shouwmore(index){
      this.hoverItem = index
      this.$store.dispatch("addIndex",index);
      // console.log('this.shouwmore2()',this.shouwmore2)
      // a(this.shouwmore2,4000)
    },
    shouwmore2() {
      // console.log('执行')
      // this.hoverItem = this.$store.state.index;
      // if(this.timers){
      //     // console.log("this.timers",this.timers)
      //     // clearTimeout(this.timers);
      //     this.timers = null
      // } else {
      //     this.timers = window.setTimeout(()=>{
      //     // console.log('添加定时器')
      //     this.hoverItem = -1;
      //     this.timers= null
      //   },4000)
      // }
    },
    intoChildIndex() { 
      this.intoChild = !this.intoChild;
    },
    hiddenMore() {
      this.hoverItem = -1;
    },
    hiddenMoreFromUp() { 
        // if(this.showupindex == false ){
        //     this.hoverItem = -1
        // }
    },
    soLong() {
      this.toLong = !this.toLong;
    },
    handleOpen(key, keyPath) {
      console.log("123",key, keyPath);

    },
    handleClose(key, keyPath) {
      console.log("456",key, keyPath);
    },
    closecT() {
      this.drawer = false;
    },

  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          if (that.screenWidth > 1150) {
            that.drawer = false;
          }
          that.timer = false;
        }, 400);
      }
    },
  },
};
</script>
<style scoped>
    /* @import "../../../node_modules/swiper/swiper.min.css"; */
    .header {
        width: 100%;
    }
    .banner {
        animation: 2s opacity2 0s infinite;
        -webkit-animation: 2s opacity2 0s infinite;-moz-animation: 2s opacity2 0s infinite;
        position: relative;
        height: 315px;
        background-image: url("../../../public/image/bg_img.jpg");
        background-repeat: no-repeat;
        background-position: center center;
        transition: background-image 0.5s linear;
    }

    .banner2 {
        animation: 2s opacity2 0s infinite;
        -webkit-animation: 2s opacity2 0s infinite;-moz-animation: 2s opacity2 0s infinite;
        position: relative;
        height: 315px;
        background-image: url("../../../public/image/bg_img2.jpg");
        background-repeat: no-repeat;
        background-position: center center;
        transition: background-image 0.5s linear;
    }
    .banner3 {
        position: relative;
        height: 315px;
        background-image: url("../../../public/image/bg_img.jpg");
        background-repeat: no-repeat;
        background-position: center center;
        transition: width 1s, height 1s, transform 1s;
        -moz-transition: width 1s, height 1s, -moz-transform 1s;
        -webkit-transition: width 1s, height 1s, -webkit-transform 1s;
        -o-transition: width 1s, height 1s, -o-transform 1s;
        transition: background-image 0.5s linear;
    }
    @keyframes banner2{
        0%{opacity:0}
        50%{opacity:.8;}
        /* 100%{opacity:0;} */
    }
    .tabber {
        display: block;
        width: 100%;
        height: 54px;
        background-color: #F7F7F7;
        color: #000000;
    }
    .ab {
        position: absolute;
        width: 100px;
        height: 49px;
        bottom: 0px;
        left: 50%;
        display: block;
        /* display: none; */
    }

    .ab:hover {
        cursor: pointer;
    }
    .ab > div {
        width: 10px;
        height: 10px;
        border: 1px solid gray;
        border-radius: 50%;
        float: left;
        margin: 10px;
    }
    .actbanner {
        background-color: #fff;
    }
    .tabber:hover {
        cursor: pointer;
    }
    .tabberList {
        float: left;
        width: 11%;
        height: 54px;
        line-height: 54px;
        text-align: center;
        z-index: 9;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        transition: width 1s, height 1s, transform 1s;
        -moz-transition: width 1s, height 1s, -moz-transform 1s;
        -webkit-transition: width 1s, height 1s, -webkit-transform 1s;
        -o-transition: width 1s, height 1s, -o-transform 1s;
        /* overflow: hidden; */
    }
    .tabberList > div {
        width: 100%;
        color: #777777;
    }
    .tabberList:hover {
        background-color: #F7F7F7;
    }
    .tabberList:first-child {
        border: none;
    }
    .tabberList:last-child {
        border: none;
    }
    /* .tabberList:hover {
        background-color: #1F518C;
    } */
    .tabberList:nth-child(3) {
        /* height: 100px;  */
    }
    .logo_wrapper {
        width: 100%;
        max-width: 1200px;
        height: 100px;
        margin: 0 auto;
    }
    .logo {
        height: 80px;
        float: left;
        margin-top: 20px;
    }
    .logo img {
        width: 416px;
        height: 100%;
    }
    .curor {
        float: right;
        height: 80%;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .curor img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
    .wTabbar {
        float: right;
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;
    }
    .wTabbar img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
    .swiper-container {
        width: 500px;
        height: 300px;
        background-color: red;
    }
    .moreList:nth-child(1) {
        border-top:1px solid #DEE466;
    }
    .moreList {
        background-color: #F7F7F7;
        transition: width 1s, height 1s, transform 1s;
        -moz-transition: width 1s, height 1s, -moz-transform 1s;
        -webkit-transition: width 1s, height 1s, -webkit-transform 1s;
        -o-transition: width 1s, height 1s, -o-transform 1s;
    }
    .moreList:hover {
        color: red;
    }
    .canve {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ffff;
        border-radius: 16px 16px 16px 16px;
        margin-left: 20px;
    }
    .canve input {
        width: 0px;
        height: 100%;
        color: #ffff;
        outline: none;
        border: none;
        background-color: rgba(0, 0, 0, 0);
        transition: width 1s, height 1s, transform 1s;
        -moz-transition: width 1s, height 1s, -moz-transform 1s;
        -webkit-transition: width 1s, height 1s, -webkit-transform 1s;
        -o-transition: width 1s, height 1s, -o-transform 1s;
    }
    .canve input::-webkit-input-placeholder {
    /* WebKit browsers */
        color: #ccc;
    }
    .canve input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
        color: #ccc;
    }
    .canve input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
        color: #ccc;
    }
    .canve input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
        color: #ccc;
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
        opacity: 0;
    }
    .el-drawer {
        display: none;
    }
    .header_wrap {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .headr_right {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .minlieb {
        display: none;
    }
    .maxshow {
        display: inline-block; 
        height: 100%; color: gray
    }
    .maximg {
        padding: 0px 20px 0px 0px; 
        width: 50px; 
        height: 50px
    }

@media screen and (max-width: 1200px) {
  .logo_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .banner {
    height: 70px;
    width: 100%;
    background-image: url("../../../public/image/bg_img.jpg");
    background-repeat: no-repeat;
    background-position: center center;
  }
  .ab {
    position: absolute;
    width: 100px;
    height: 49px;
    bottom: 0px;
    left: 600px;
    display: none;
  }
  .ab:hover {
    cursor: pointer;
  }
  .ab > div {
    width: 6px;
    height: 6px;
    border: 1px solid gray;
    border-radius: 3px;
    float: left;
    margin: 10px;
  }
  .logo {
    height: 100%;
    float: left;
    margin-top: 0px;
  }
  .logo img {
    margin-top: 5px;
    height: 60px;
    width: 416px;
  }
  .curor {
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
  }
  .curor img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .wTabbar {
    float: right;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: block;
  }
  .wTabbar img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-top: 5px;
    margin-right: -20px;
    border: 1px solid #ffff;
  }
  .tabber {
    display: none;
    width: 100%;
    height: 54px;
    background-color: #022f64;
    color: #ffff;
  }
  .el-drawer__body {
    width: 100%;
  }
  .el-drawer__container .el-drawer__open > div {
    width: 100%;
  }
  .el-drawer {
    width: 100% !important;
  }
  .ltr {
    width: 100% !important;
  }
  .el-menu-vertical-demo {
    color: #1b5597;
  }

  .minlieb {
        display:block;
    }
   .maxshow {
        display: none; 
        height: 100%; color: gray
    }
    .maximg {
        display: none;
    }
}

</style>