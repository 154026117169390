<template>
  <div id="app">
     <Header/>
      <router-view/>
      <Footer></Footer>
 
  </div>
</template>
<script>
import Header from '@/components/Header/header'
import Footer from '@/components/Footer/footer.vue'

export default {
   components: {
    Header,
    Footer

  }
}
</script>

<style scoped>
* {
  padding: 0px;
  margin: 0px;
  font-family: "Microsoft YaHei" !important;
}
</style>