import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    index:0
  },
  mutations: {
    addIndex(state,index){
      state.index=index
    }
  },
  actions: {
    addIndex(context,index){
      context.commit('addIndex',index)
    }
  },
  modules: {
  }
})
