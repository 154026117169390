<template>
  <div class="home">
    <div v-bind:class="{ banner: a == 1, banner2: a == 2, banner3: a == 3 }">
       <!-- <div class="ab">
        <div :class="a==1?'actbanner':''" @click="bgClick1"></div>
        <div :class="a==2?'actbanner':''" @click="bgClick"></div>
        <div :class="a==3?'actbanner':''" @click="bgClick2"></div>
    </div> -->
      <!-- <img style="heigth:400px" src="../../public/image/bg_img2.jpg" alt=""> -->
    </div>
    <!-- 基金项目 -->
    <div class="home_content">
      <div class="home_l">
        <div class="l_header">
          <h3>基金项目</h3>
          <ul style="font-size: 14px">
            <li v-for="(item,index) in selist" :key="index" @click="clicksel(index)"  :class="seloneindex == index?'selone':''">{{item}}</li>
          </ul>
        </div>
        <div class="l_list_wrap">
           <div class="l_list" @click="clickxiangmu">
              <div style="width: 250px; height: 150px; overflow: hidden">
                <img
                  class="l_list_img"
                  src="../../public/image/one.jpg"
                  alt=""
                />
              </div>
              <div
                style="width: 540px; padding: 0px 40px 0px 40px; color: #666666"
              >
                <a class="news_tit">更新中…</a>
                <div class="news_zi">
                  <!-- 每段青春都应该经历一次支教，每个灵魂都应该带着爱与微笑。索玛花支教项目，孩子们在山里等你来。山在那里，希望在那里。 -->
                </div>
                <!-- <i class="news_time">2020-06-08</i> -->
              </div>
            </div>
            <div class="l_list" @click="clickxiangmu">
              <div style="width: 250px; height: 150px; overflow: hidden">
                <img
                  class="l_list_img"
                  src="../../public/image/two.jpg"
                  alt=""
                />
              </div>
              <div
                style="width: 540px; padding: 0px 40px 0px 40px; color: #666666"
              >
                <a class="news_tit">更新中…</a>
                <div class="news_zi">
                  <!-- 静脉血栓栓塞症（VTE）是一个全球性的疾病，包括深静脉血栓栓塞症（DVT）+肺动脉栓塞（PE）。发病隐匿，来势凶猛，在西方国家，每16秒就有1个人发生VTE，每37秒就有1人因VTE死亡，每年死亡人数超过843,
                  000。VTE是继缺血性心脏病和卒中之后位列第三的最常见的心血管疾病。然而临床上漏误诊率极高，治疗上也多有不规范现象，涉及科室广，不同学科对VTE的诊治水平差异较大，且尚未建立国人规范的诊疗技术体系。 -->
                </div>
                <!-- <i class="news_time">2020-06-08</i> -->
              </div>
            </div>
            <div class="l_list" @click="clickxiangmu">
              <div style="width: 250px; height: 150px; overflow: hidden">
                <img
                  class="l_list_img"
                  src="../../public/image/three.jpg"
                  alt=""
                />
              </div>
              <div
                style="width: 540px; padding: 0px 40px 0px 40px; color: #666666"
              >
                <a class="news_tit" >更新中…</a  >
              <div class="news_zi"> 
                <!-- 品牌项目：中青年医生火炬项目
                自从2018年开启中青年医生火炬项目，基金会关注中青年医生人群的在医疗卫生领域的痛点、难点，切实从群体出发，希望能够为工作在医疗卫生一线的工作人员，排忧解难，尽我们的绵薄之力。
                2020年共立项6个课题，6个课题正在实施研究，共计资助2490000元，第一笔资助款共1160000元已于2021年1月20日之前全部拨付。
                中青年医生火炬项目不断深化医学人才的培养,推进科技赋能医疗领域实现创新融合，更好地促进医疗卫生产业的良性发展。 -->
              </div>
                <!-- <i class="news_time">2020-06-08</i> -->
              </div>
            </div>
            
            <!-- 信息公开 -->
          <div class="l_listx">
                <div class="l_header">
                <h3>信息公开</h3>
                  <!-- <ul style="font-size: 14px">
                    <li>教育类项目</li>
                    <li>助学类项目</li>
                    <li>扶贫类项目</li>
                    <li>基础建设项目</li>
                  </ul> -->
                </div>
              <div class="xinxilist">
                <p @click="clickinfo"><a>审  计  报  告</a> <a style="padding-left:20px">2020-07-30</a></p>
                <!-- <p @click="clickinfo">北京新兴卫生产业发展基金会2019年年检报告 <a style="padding-left:20px">2020-07-30</a></p>
                <p @click="clickinfo">北京新兴卫生产业发展基金会2018年年检报告 <a style="padding-left:20px">2020-07-30</a></p>
                <p @click="clickinfo">北京新兴卫生产业发展基金会2019年年检报告 <a style="padding-left:20px">2020-07-30</a></p>
                <p @click="clickinfo">北京新兴卫生产业发展基金会2019年年检报告 <a style="padding-left:20px">2020-07-30</a></p>
                <p @click="clickinfo">北京新兴卫生产业发展基金会2019年年检报告 <a style="padding-left:20px">2020-07-30</a></p>
                <p @click="clickinfo">北京新兴卫生产业发展基金会2019年年检报告 <a style="padding-left:20px">2020-07-30</a></p>
                <p @click="clickinfo">北京新兴卫生产业发展基金会2019年年检报告 <a style="padding-left:20px">2020-07-30</a></p> -->
              </div>
          </div>
        </div>
      </div>
      <div class="home_r">
        <div class="organ">
          <div class="or_tit clearfix">
            <p class="organ_h3">
               <!-- 机构党建 -->
            </p>
            <!-- <a  class="or_a">更多...</a> -->
          </div>

          <div class="or_div">
             <dl class="or_dl" @click="clickdj">
              <dt>
                <a 
                  >更新中…</a
                >
              </dt>
              <!-- <dd>2019-11-24</dd> -->
            </dl>
<!--
            <dl class="or_dl" @click="clickdj">
              <dt>
                <a 
                  >公示2020年党费收缴情况 推进党务公开工作</a
                >
              </dt>
              <dd>2020-09-04</dd>
            </dl>

            <dl class="or_dl" @click="clickdj">
              <dt>
                <a 
                  >大山深处，党徽闪耀</a
                >
              </dt>
              <dd>2020-07-01</dd>
            </dl>

            <dl class="or_dl" @click="clickdj">
              <dt>
                <a 
                  >关于中共四川省索玛慈善基金会党支部选举结果报告</a
                >
              </dt>
              <dd>2019-11-30</dd>
            </dl>

            <dl class="or_dl" @click="clickdj">
              <dt>
                <a  
                  >关于同意中共四川省索玛慈善基金会支部开展选举工作的批复</a
                >
              </dt>
              <dd>2019-11-27</dd>
            </dl> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 基金咨询 -->
    <div class="zixun">
      <div class="zixun_header">
        <b style="line-height:50px">基金资讯</b>
        <!-- <ul >
          <li>学校与志愿者</li>
          <li>每月简报</li>
          <li>索玛花动态</li>
          <li>公告公示</li>
        </ul> -->
      </div>
      <div class="zixun_content">
        <div @click="clickzx">
          <img  src="../../public/image/img.jpg" alt="">
          <p style="width: 230px;font-size:14px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">有关北京黉塾公益基金会</p>
        </div>
        <div>
          <img  src="../../public/image/img.jpg" alt="">
          <p style="width: 230px;font-size:14px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis; ">有关北京黉塾公益基金会</p>
        </div>
        <div>
          <img  src="../../public/image/img.jpg" alt="">
          <p style="width: 230px;font-size:14px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis; ">有关北京黉塾公益基金会</p>
        </div>
        <div>
          <img  src="../../public/image/img.jpg" alt="">
          <p style="width: 230px;font-size:14px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis; ">有关北京黉塾公益基金会</p>
        </div>
        <div>
          <img  src="../../public/image/img.jpg" alt="">
          <p style="width: 230px;font-size:14px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis; ">慈善法中的慈善、公益究竟应该如何界定？</p>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  components: {
    // HelloWorld
  },
  data() {
    return {
      a: 1,
      seloneindex:0,
      selist:[
        "教育类项目","助学类项目","扶贫类项目","基础建设项目"
      ]
    };
  },
  mounted() {
    setTimeout(() => {
      this.a = 2;
    }, 2000);
    setTimeout(() => {
      this.a = 3;
    }, 4000);
  },
  methods: {
    bgClick() {
      this.a = 2;
    },
    bgClick1() {
      this.a = 1;
    },
    bgClick2(){
      this.a = 3;
    },
    clicksel(index){
      this.seloneindex = index
    },
    // 基金项目页面跳转
    clickxiangmu(){
      var item =  {
            name: "基金项目",
            path: "/xmu",
            childItem: [
                {
                name: "基金项目",
              },
            ],
        }
      window.sessionStorage.setItem("titleMessage", JSON.stringify(item));
      this.$router.push({
        path: '/xmu',
        query: {
          index: 0,
         },
      });
    },
    // 信息公开页面跳转
    clickinfo(){
      var item = {
          name: "信息公开",
          path: "/info",
          childItem: [
              {
              name: "信息公开",
            },
          ],
        }
      window.sessionStorage.setItem("titleMessage", JSON.stringify(item));
         this.$router.push({
        path: '/info',
        query: {
          index: 0,
         },
      })
    },
    // 资讯页面跳转
    clickzx(){
       var item =  {
          name: "基金咨询",
          path: "/zxun",
          childItem: [
            {
              name: "新闻资讯",
            },
            {
              name: "党建资讯",
            },
          ],
        }
      window.sessionStorage.setItem("titleMessage", JSON.stringify(item));
         this.$router.push({
        path: '/zxun',
        query: {
          index: 0,
         },
      })
    },
    // 基金党建跳转
    clickdj(){
       var item =  {
          name: "基金党建",
          path: "/djian",
          childItem: [
            {
              name: "审计报告",
            },
            {
              name: "年检报告",
            },
          ],
        }
      window.sessionStorage.setItem("titleMessage", JSON.stringify(item));
         this.$router.push({
        path: '/djian',
        query: {
          index: 0,
         },
      })
    }
  },

};
</script>
<style scoped>
.home {
  width: 100%;
}

.banner {
  /* width: 100%; */
  animation: 2s opacity2 0s infinite;
  -webkit-animation: 2s opacity2 0s infinite;
  -moz-animation: 2s opacity2 0s infinite;
  position: relative;
  height: 450px;
  background-image: url("../../public/image/banner.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  transition: background-image 0.5s linear;
  
  /* transition: width 2s, height 2s, transform 2s; */
}

.banner2 {
  animation: 2s opacity2 0s infinite;
  -webkit-animation: 2s opacity2 0s infinite;
  -moz-animation: 2s opacity2 0s infinite;
  position: relative;
  height: 450px;
  background-image: url("../../public/image/banner.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  transition: background-image 0.5s linear;
}
.banner3 {
  position: relative;
  height: 450px;
  background-image: url("../../public/image/banner.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  transition: width 1s, height 1s, transform 1s;
  -moz-transition: width 1s, height 1s, -moz-transform 1s;
  -webkit-transition: width 1s, height 1s, -webkit-transform 1s;
  -o-transition: width 1s, height 1s, -o-transform 1s;
  transition: background-image 0.5s linear;
}
.home_content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
}
.home_l {
  width: 840px;
}
.home_r {
  float: right;
  width: 450px;
  margin: 24px 0px 0px 40px;
  

}
.home_l {
  width: 100%;
}
.l_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #dee466;
}
.l_header:hover{
  cursor: pointer;
}
ul li {
  list-style: none;
}
.l_header ul li {
  float: left;
  padding: 0px 32px;
}
.l_header ul li:hover {
  color: red;
  cursor: pointer;
}
.l_list_wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.l_list {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid #eeeeee;
}
.l_list:hover .news_tit {
  color: #d71e06;
  margin-left: 20px;
  cursor: pointer;

}

.l_list_img {
  width: 100%;
  height: 100%;
  transition: width 1s, height 1s, transform 1s;
  /* animation: 2s opacity2 0s infinite;
  -webkit-animation: 2s opacity2 0s infinite;-moz-animation: 2s opacity2 0s infinite; */
}
.l_list_img:hover {
  transform: scale(1.2);
}
.cont_title {
  width: 100%;
  height: 50px;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.news_tit {
  display: block;
  transition: all 1s ease 0s;
  overflow: hidden;
  height: 52px;
  line-height: 52px;
  color: #666666;
  font-size: 16px;
  font-weight: bold;
  /* transition-property: all;
  transition-duration: 1s;
  transition-timing-function: linear; */
}
.news_zi {
  height: 50px;
  line-height: 25px;
  overflow: hidden;
  font-size: 14px;
  color: #666666;
}
.news_time {
  height: 46px;
  line-height: 46px;
  overflow: hidden;
  display: block;
  color: #666666;
  font-style:normal
}

.or_tit {
  border-bottom: 1px dotted #000000;
  height: 54px;
  line-height: 54px;
  overflow: hidden;
}
.organ_h3 {
  float: left;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}
.organ {
    background: #FAFAFA;
    padding: 0 14px;
    margin-bottom: 20px;
    border: 1px solid #e3e3e3;
    border-top: 2px solid #d71e06;
}
a {
  text-decoration: none;
}
.or_a {
    display: block;
    float: right;
    color: #fff;
    font-size: 14px;
    transition: all 0.5s ease 0s;
}
.or_a:hover {
  color: #d71e06;
  cursor: pointer;
}
.or_dl dt a {
    font-size: 14px;
    height: 46px;
    transition: all 0.5s ease 0s;
    display: block;
    line-height: 23px;
    overflow: hidden;
    font-weight: bold;
}
.or_dl dt a:hover {
  color: #d71e06;
  cursor: pointer;
}
.or_div {
    padding-bottom: 30px;
    height: 530px;
}
dt {
    display: block;
}
.or_dl {
    /* background: url(.) left 7px no-repeat; */
    /* padding-left: 17px; */
    margin-top: 18px;
}
a {
    color: #333333;
    text-decoration: none;
}
.or_dl dd {
    height: 30px;
    line-height: 30px;
    color: #9b9b9b;
    font-size: 12px;
    margin-left: 0px;
}
.xinxilist {
    width:100%;
    display:flex;
    flex-wrap:wrap;
}
.xinxilist:hover {
  cursor: pointer;
}
.xinxilist>p {
  width: 45%;
  font-size: 14px;
  color: #666666;
  display: flex;
  justify-content: space-between;
}
.xinxilist>p:nth-of-type(even){
  padding: 0px 0px 0px  40px;
}
.l_listx {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid #eeeeee;
}
.zixun {
  max-width: 1200px;
  margin: 0 auto;
}
.zixun_header {
  width: 100%;
  display: flex;
  justify-content:space-between;
  border-bottom: 1px solid #DEE466;
}
.zixun_header ul li {
  float: left;
  padding: 0px 50px 0px 0px;
  font-size: 14px;
}
.zixun_header ul li:hover {
  cursor: pointer;
  transition: all 0.5s ease 0s;
  color: #d71e06;
}
.zixun_content {
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:  20px 0px 0px 0px ;
}
.zixun_content>div {
 
}
.zixun_content>div img {
   width: 230px;
  height: 160px;
}
.ab {
  position: absolute;
  width: 100px;
  height: 49px;
  bottom: 0px;
  left: 50%;
  display: block;
  /* display: none; */
}
.ab:hover {
  cursor: pointer;
}
.ab > div {
  width: 10px;
  height: 10px;
  border: 1px solid gray;
  border-radius: 50%;
  float: left;
  margin: 10px;
}
.actbanner {
  background-color: #fff;
}
.selone {
  color: #d71e06;
}
</style>

